import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        class: _normalizeClass(["hover:bg-grey-light hover:text-black font-bold flex flex-col justify-center text-center", [
        link.active.includes(_ctx.$route.name) ? 'bg-grey-light text-black' : '',
        _ctx.mobile ? 'py-6 px-8' : 'p-3',
      ]]),
        key: link.label(),
        to: link.to,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('linkClicked')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(link.label()), 1)
        ]),
        _: 2
      }, 1032, ["to", "class"]))
    }), 128))
  ]))
}