import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ArrowIcon = _resolveComponent("ArrowIcon")!
  const _component_SubMenu = _resolveComponent("SubMenu")!
  const _component_LanguageMenu = _resolveComponent("LanguageMenu")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(_ctx.mobile ? 'overflow-y-auto' : 'flex items-end ')
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: link.label(),
        class: "font-bold whitespace-nowrap cursor-pointer relative hover:bg-green-500 hover:text-grey-light"
      }, [
        (link.to)
          ? (_openBlock(), _createBlock(_component_router_link, {
              onClick: _ctx.closeMenus,
              class: _normalizeClass(["px-8 py-4 block text-left", link.active.includes(_ctx.$route.name) ? 'text-grey-light bg-green-500' : '']),
              key: link.label(),
              to: link.to
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(link.label()), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "class", "to"]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["relative pl-8 pr-6 py-4 text-left whitespace-nowrap", link.active.includes(_ctx.$route.name) ? 'text-grey-light bg-green-500' : '']),
              onClick: ($event: any) => (_ctx.open === index ? (_ctx.open = undefined) : (_ctx.open = index))
            }, [
              _createElementVNode("span", null, _toDisplayString(link.label()), 1),
              (index !== _ctx.open)
                ? (_openBlock(), _createBlock(_component_ArrowIcon, {
                    key: 0,
                    class: "inline-block ml-1",
                    type: "down",
                    width: 18
                  }))
                : (_openBlock(), _createBlock(_component_ArrowIcon, {
                    key: 1,
                    class: "inline-block ml-1",
                    type: "up",
                    width: 18
                  }))
            ], 10, _hoisted_1)),
        (link.children && _ctx.open === index)
          ? (_openBlock(), _createBlock(_component_SubMenu, {
              key: 2,
              onClicked: _ctx.closeMenus,
              class: _normalizeClass([_ctx.mobile ? '' : 'absolute', "z-50"]),
              links: link.children,
              mobile: _ctx.mobile
            }, null, 8, ["onClicked", "class", "links", "mobile"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    (_ctx.mobile)
      ? (_openBlock(), _createBlock(_component_LanguageMenu, {
          key: 0,
          onSelected: _ctx.closeMenus,
          class: "flex align-middle text-black ml-6 mt-4"
        }, null, 8, ["onSelected"]))
      : _createCommentVNode("", true)
  ], 2))
}