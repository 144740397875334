
import { defineComponent, Ref, ref } from 'vue';
import VerseSelect from '@/components/verse_navigation/VerseSelect.vue';
import VerseLinks from '@/components/verse_navigation/VerseLinks.vue';
import MenuIcon from '@/components/icons/MenuIcon.vue';
import MobileNav from '@/components/header/MobileNav.vue';

export default defineComponent({
  components: {
    VerseSelect,
    VerseLinks,
    MenuIcon,
    MobileNav,
  },
  setup() {
    const mobileMenu: Ref<boolean> = ref(false);

    return { mobileMenu };
  },
});
