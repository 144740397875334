
import { defineComponent, ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { isLoaded } from '@/interfaces/RemoteData';
import { getChapters, chapters } from '@/api/chapters';
import { determineVerse } from '@/router/route_to_verse';

export default defineComponent({
  setup() {
    getChapters();
    const selectedChapter = ref<number>(0);
    const selectedVerse = ref<number>(0);

    const route = useRoute();
    const router = useRouter();

    const verse = determineVerse(route);
    selectedChapter.value = verse.sura;
    selectedVerse.value = verse.verse;

    watch(
      [
        () => route.params.sura,
        () => route.params.verse,
        () => route.query.sura,
        () => route.query.verse,
      ],
      () => {
        const newVerse = determineVerse(route);
        selectedChapter.value = newVerse.sura;
        selectedVerse.value = newVerse.verse;
      },
    );

    const verses = computed(() => {
      if (isLoaded(chapters.value)) {
        const chapter = chapters.value.payload.find((c) => c.key === selectedChapter.value);
        if (chapter) {
          return chapter.verses;
        }
        return 0;
      }
      return 0;
    });

    function setSuraAndResetVerse(event: any) {
      selectedChapter.value = +event.target.value;
      selectedVerse.value = 0;
    }

    function navigate() {
      if (route.params.sura && route.params.verse) {
        router.push({
          params: {
            sura: selectedChapter.value,
            verse: selectedVerse.value,
            word: 1,
          },
        });
      } else {
        router.push({
          query: {
            sura: selectedChapter.value,
            verse: selectedVerse.value,
          },
        });
      }
    }

    return {
      chapters,
      verses,
      selectedChapter,
      selectedVerse,
      navigate,
      setSuraAndResetVerse,
    };
  },
});
