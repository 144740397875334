import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.imageCaption)
    ? (_openBlock(), _createElementBlock("figcaption", {
        key: 0,
        class: _normalizeClass(["showLinks text-center", _ctx.largeCaption ? 'text-lg' : 'text-xs'])
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('global.image_credits')) + " ", 1),
        (_ctx.imageCaptionLink)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.imageCaptionLink,
              target: "_blank"
            }, _toDisplayString(_ctx.imageCaption), 9, _hoisted_1))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.imageCaption), 1)
            ], 64))
      ], 2))
    : _createCommentVNode("", true)
}