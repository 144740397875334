
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { verseInRange, VerseRange } from '@/interfaces/VerseRange';
import { Verse } from '@/interfaces/Verse';
import { routeQueryToVerse } from '@/router/route_to_verse';
import { manuscriptDetail } from '@/api/manuscripts';
import { isLoaded } from '@/interfaces/RemoteData';

export default defineComponent({
  setup() {
    const route = useRoute();
    const pagesForVerse = computed(() => {
      const routeVerse = ref<Verse>(routeQueryToVerse(route));
      if (isLoaded(manuscriptDetail.value)) {
        const { pages } = manuscriptDetail.value.payload;
        return pages.filter((page) => page.passages.some((passage) => {
          const range: VerseRange = {
            start: {
              sura: passage.start.sura,
              verse: passage.start.verse,
            },
            end: {
              sura: passage.end.sura,
              verse: passage.end.verse,
            },
          };
          return verseInRange(routeVerse.value)(range);
        }));
      }
      return [];
    });

    return {
      pagesForVerse,
      manuscriptDetail,
      isLoaded,
    };
  },
});
