
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { verseLinks } from '@/components/verse_navigation/verse_links';

export default defineComponent({
  props: { mobile: Boolean },
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });

    const links = verseLinks(t);
    return { links };
  },
});
