import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center flex-wrap w-full" }
const _hoisted_2 = { class: "md:order-2 order-1 w-full md:w-min flex-grow my-2 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayButton = _resolveComponent("PlayButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PlayButton, {
      class: "md:order-1 order-2 my-2",
      text: _ctx.textLeft,
      arrowType: "back",
      textFirst: false,
      internalLink: _ctx.leftLink,
      disabled: !_ctx.leftLink
    }, null, 8, ["text", "internalLink", "disabled"]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode(_component_PlayButton, {
      class: "md:order-3 order-2 my-2",
      text: _ctx.textRight,
      arrowType: "forward",
      textFirst: true,
      internalLink: _ctx.rightLink,
      disabled: !_ctx.rightLink
    }, null, 8, ["text", "internalLink", "disabled"])
  ]))
}