
import { defineComponent } from 'vue';
import ArrowIcon from '@/components/icons/ArrowIcon.vue';

export default defineComponent({
  components: { ArrowIcon },
  props: {
    arrowType: String,
    text: String,
    textFirst: Boolean,
  },
});
