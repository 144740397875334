
import { computed, defineComponent, ref, watch } from 'vue';
import LoadingSpinner from '@/components/global/LoadingSpinner.vue';
import FigureCaption from '@/components/global/FigureCaption.vue';

export default defineComponent({
  components: {
    LoadingSpinner, FigureCaption,
  },
  props: {
    imageRatio: Number,
    imageUrl: String,
    imageDescription: String,
    imageCaption: String,
    imageCaptionLink: String,
    largeCaption: Boolean,
  },
  setup(props) {
    const isLoaded = ref(false);
    const isError = ref(false);

    watch(
      () => props.imageUrl,
      () => {
        isLoaded.value = false;
      },
    );

    const imgHolder = ref<HTMLElement | null>(null);

    const loadingHeight = computed(() => {
      if (props.imageRatio && imgHolder.value) {
        return imgHolder.value?.clientWidth * props.imageRatio;
      }
      return 50;
    });

    return {
      isError,
      isLoaded,
      imgHolder,
      loadingHeight,
    };
  },
});
