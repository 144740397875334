import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowNavigation = _resolveComponent("ArrowNavigation")!

  return (_openBlock(), _createBlock(_component_ArrowNavigation, {
    textLeft: _ctx.$t('navigation.next_page'),
    textRight: _ctx.$t('navigation.previous_page'),
    leftLink: _ctx.nextPage,
    rightLink: _ctx.previousPage
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(`${_ctx.$t('manuscripts.folio_and_page')} ${_ctx.$route.params.page}`), 1)
    ]),
    _: 1
  }, 8, ["textLeft", "textRight", "leftLink", "rightLink"]))
}