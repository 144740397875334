
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import NavigationLink from '@/interfaces/NavigationLink';
import mainLinks from '@/components/header/main_links';
import LanguageMenu from '@/components/header/LanguageMenu.vue';
import SubMenu from '@/components/header/SubMenu.vue';
import ArrowIcon from '@/components/icons/ArrowIcon.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    SubMenu,
    ArrowIcon,
    LanguageMenu,
  },
  props: { mobile: Boolean },
  setup(props, context) {
    const {
      t, locale,
    } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const route = useRoute();
    const open = ref<number | undefined>(undefined);

    const links = computed(() => mainLinks(t, route, locale));

    function closeMenus() {
      open.value = undefined;
      context.emit('linkClicked');
    }
    return {
      links,
      open,
      closeMenus,
    };
  },
});
