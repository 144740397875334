
import { defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import PlayButton from '@/components/global/PlayButton.vue';

export default defineComponent({
  components: { PlayButton },
  props: {
    textLeft: String,
    textRight: String,
    leftLink: Object as PropType<RouteLocationRaw>,
    rightLink: Object as PropType<RouteLocationRaw>,
  },
});
