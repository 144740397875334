
import { defineComponent, onMounted, PropType } from 'vue';
import { useRoute } from 'vue-router';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import { InfoItem } from '@/interfaces/InfoItem';
import ImageHolder from '../global/ImageHolder.vue';
import scrollToHash from '../global/scrollToHash';

export default defineComponent({
  components: {
    ArticleContainer,
    ImageHolder,
  },
  props: { item: Object as PropType<InfoItem> },
  setup() {
    const publicPath = process.env.BASE_URL;
    const route = useRoute();
    onMounted(() => {
      scrollToHash(route);
    });
    return { publicPath };
  },
});
