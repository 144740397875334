import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "w-full flex-column py-3" }
const _hoisted_2 = { class: "py-1 px-3 text-xl bg-white shadow" }
const _hoisted_3 = { class: "flex flex-row" }
const _hoisted_4 = { class: "text-black" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "bg-white flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MoreInfo = _resolveComponent("MoreInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1),
        (_ctx.informationText)
          ? (_openBlock(), _createBlock(_component_MoreInfo, {
              key: 0,
              showInfo: _ctx.showInfo,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInfo = !_ctx.showInfo)),
              class: "ml-4"
            }, null, 8, ["showInfo"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: "",
            innerHTML: _ctx.subtitle
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.showInfo ? 'max-h-large' : 'max-h-0', "text-m overflow-hidden transition transition-maxHeight ease-in-out duration-700"])
    }, [
      _createElementVNode("div", {
        class: "bg-grey-verylight px-3 py-5",
        innerHTML: _ctx.informationText
      }, null, 8, _hoisted_6)
    ], 2),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}