import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "howtoquote p-4 my-4 bg-white" }
const _hoisted_2 = { class: "text-xl" }
const _hoisted_3 = { class: "py-2 text-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('global.how_to_cite')), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.precitation ? _ctx.precitation + _ctx.mainCitation : _ctx.mainCitation), 1)
  ]))
}