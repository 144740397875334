import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap items-center" }
const _hoisted_2 = {
  href: "#catalogue_entry",
  class: "py-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GeneralInfo = _resolveComponent("GeneralInfo")!
  const _component_PageSelect = _resolveComponent("PageSelect")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_SimpleCard = _resolveComponent("SimpleCard")!

  return (_openBlock(), _createBlock(_component_SimpleCard, { class: "w-full block mx-auto" }, {
    default: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(('title' in _ctx.manuscript) ?
      _ctx.manuscript.title :
      _ctx.manuscript.archive.name + ': ' + _ctx.manuscript.call_number), 1),
      _createVNode(_component_GeneralInfo, { manuscript: _ctx.manuscript }, null, 8, ["manuscript"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PageSelect, {
          pages: _ctx.manuscript.pages,
          class: "flex-grow min-width-0"
        }, null, 8, ["pages"]),
        _createElementVNode("a", _hoisted_2, [
          _createVNode(_component_PrimaryButton, { class: "max-w-full w-72" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('manuscripts.to_catalogue_entry')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}