
import { defineComponent, ref } from 'vue';
import MoreInfo from '@/components/global/MoreInfo.vue';

export default defineComponent({
  components: { MoreInfo },
  props: {
    title: String,
    subtitle: {
      type: String,
      required: false,
    },
    informationText: {
      type: String,
      required: false,
    },
  },
  setup() {
    const showInfo = ref(false);

    return { showInfo };
  },
});
