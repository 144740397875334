import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "text-center",
  id: "catalogue_entry"
}
const _hoisted_2 = { class: "pt-4" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "pt-4 showLinks list-disc mx-7" }
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArticleSection = _resolveComponent("ArticleSection")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('manuscripts.catalogue_entry')), 1),
    _createVNode(_component_ArticleContainer, { class: "mt-3 mb-6 showLinks" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.manuscriptTitle(_ctx.manuscript)), 1),
        _createVNode(_component_ArticleSection, null, {
          default: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('manuscripts.editors')), 1),
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.manuscript.editors ? _ctx.manuscript.editors : _ctx.manuscript?.citation), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ArticleSection, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.manuscript.commentary || _ctx.manuscript.catalogue_entry,
              class: "pt-4 showLinks"
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        }),
        (_ctx.manuscript.codicology)
          ? (_openBlock(), _createBlock(_component_ArticleSection, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('manuscripts.codicology')), 1),
                _createElementVNode("div", {
                  class: "pt-4",
                  innerHTML: _ctx.manuscript.codicology
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.manuscript.paleography)
          ? (_openBlock(), _createBlock(_component_ArticleSection, { key: 1 }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('manuscripts.palaeography')), 1),
                _createElementVNode("div", {
                  class: "pt-4",
                  innerHTML: _ctx.manuscript.paleography
                }, null, 8, _hoisted_5)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.manuscript.literature)
          ? (_openBlock(), _createBlock(_component_ArticleSection, { key: 2 }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('manuscripts.literature')), 1),
                _createElementVNode("div", {
                  innerHTML: _ctx.manuscript.literature,
                  class: "pt-4 showLinks"
                }, null, 8, _hoisted_6)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.manuscript.bibliography)
          ? (_openBlock(), _createBlock(_component_ArticleSection, { key: 3 }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('manuscripts.literature')), 1),
                _createElementVNode("ul", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.manuscript.bibliography, (entry, index) => {
                    return (_openBlock(), _createElementBlock("li", { key: index }, [
                      _createElementVNode("a", {
                        href: `${entry.zotero_key}`,
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }, _toDisplayString(entry.citation), 9, _hoisted_8)
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}