import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "inline"
}
const _hoisted_2 = {
  key: 1,
  class: "inline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowIcon = _resolveComponent("ArrowIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.textFirst)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_ArrowIcon, {
      type: _ctx.arrowType,
      width: 32,
      class: "inline h-12"
    }, null, 8, ["type"]),
    (!_ctx.textFirst)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true)
  ]))
}