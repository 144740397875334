
import { computed, defineComponent, onMounted, PropType, ref, Ref, watch } from 'vue';
import OpenSeadragon from 'openseadragon';
import { OpenSeadragonOptions } from '@/interfaces/OpenSeaDragonOptions';
import SmallButton from '@/components/global/SmallButton.vue';
import ArrowIcon from '@/components/icons/ArrowIcon.vue';
import FigureCaption from '@/components/global/FigureCaption.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    ArrowIcon,
    SmallButton,
    FigureCaption,
  },
  props: {
    iiifJsonUrls: Array as PropType<Array<string>>,
    image_sources: Array as PropType<Array<string>>,
    image_links: Array as PropType<Array<string>>,
  },
  setup(props) {
    const currentImage: Ref<number> = ref(0);
    let viewer;
    const openseadragon: Ref<HTMLElement | null> = ref(null);
    const openseadragontoolbar: Ref<HTMLElement | null> = ref(null);
    const {
      t, locale,
    } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });

    const previousImage = computed(() => t('navigation.previous_image'));
    const nextImage = computed(() => t('navigation.next_image'));
    function setOpenSeadragonTooltips() {
      OpenSeadragon.setString('Tooltips.PreviousPage', previousImage.value);
      OpenSeadragon.setString('Tooltips.NextPage', nextImage.value);
    }

    watch(locale, () => {
      setOpenSeadragonTooltips();
    });

    onMounted(() => {
      OpenSeadragon.setString('Tooltips.Home', '');
      OpenSeadragon.setString('Tooltips.ZoomOut', '');
      OpenSeadragon.setString('Tooltips.ZoomIn', '');
      OpenSeadragon.setString('Tooltips.FullPage', '');
      setOpenSeadragonTooltips();

      if (props.iiifJsonUrls !== undefined) {
        viewer = OpenSeadragon({
          element: openseadragon.value,
          toolbar: openseadragontoolbar.value,
          zoomInButton: 'zoom-in',
          zoomOutButton: 'zoom-out',
          homeButton: 'home',
          fullPageButton: 'full-page',
          nextButton: 'next',
          previousButton: 'previous',
          sequenceMode: true,
          tileSources: props.iiifJsonUrls,
          showReferenceStrip: props.iiifJsonUrls.length > 1,
          referenceStripScroll: 'horizontal',
        } as OpenSeadragonOptions);

        viewer.addHandler('page', (data) => {
          currentImage.value = data.page;
        });
      }
    });

    watch(
      () => props.iiifJsonUrls,
      (iiifJsonUrls, previous) => {
        if (viewer && previous && JSON.stringify(iiifJsonUrls) !== JSON.stringify(previous)) {
          viewer.open(iiifJsonUrls);
        }
      },
    );

    return {
      currentImage,
      openseadragon,
      openseadragontoolbar,
    };
  },
});
