import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  for: "manuscripts"
}
const _hoisted_2 = {
  key: 1,
  for: "manuscripts"
}
const _hoisted_3 = {
  key: 2,
  for: "manuscripts"
}
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.justNavigation)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.$t('manuscripts.choose_manuscript')) + ":", 1))
      : (_ctx.isLoaded(_ctx.manuscriptVerseResults))
        ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.$t('manuscripts.result_count', {
        count: _ctx.manuscriptVerseResults.payload.length,
        sura: _ctx.sura,
        verse: _ctx.verse,
      })), 1))
        : (_openBlock(), _createElementBlock("label", _hoisted_3, " ")),
    _createElementVNode("select", {
      class: "w-full bg-green-200 border",
      name: "manuscripts",
      id: "manuscripts",
      value: _ctx.selected,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.manuscriptVerseResults.payload, (manuscript) => {
        return (_openBlock(), _createElementBlock("option", {
          key: manuscript.manuscript_id,
          value: manuscript.manuscript_id
        }, _toDisplayString(manuscript.title), 9, _hoisted_5))
      }), 128))
    ], 40, _hoisted_4)
  ], 64))
}