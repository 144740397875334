import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_InfoItem = _resolveComponent("InfoItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.site)
      ? (_openBlock(), _createBlock(_component_SectionHeader, {
          key: 0,
          title: _ctx.$t(_ctx.site.title)
        }, {
          default: _withCtx(() => [
            (_ctx.$route.name === 'VariantReadingsOverview')
              ? (_openBlock(), _createBlock(_component_PrimaryButton, {
                  key: 0,
                  class: "text-black w-72 mb-3 mt-5"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'VerseVariants', params: { sura: 1, verse: 1 } },
                      class: "grid place-content-center text-black h-full w-full"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('variants.by_verse')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.isLoaded(_ctx.info))
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.info.payload, (item, index) => {
          return (_openBlock(), _createBlock(_component_InfoItem, {
            key: index,
            item: item
          }, null, 8, ["item"]))
        }), 128))
      : _createCommentVNode("", true)
  ], 64))
}