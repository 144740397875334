import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-screen w-full overflow-x-hidden" }
const _hoisted_2 = { class: "flex-grow bg-grey-light flex" }
const _hoisted_3 = { class: "container mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_Header = _resolveComponent("Header")!
  const _component_VerseNavBar = _resolveComponent("VerseNavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_ErrorModal = _resolveComponent("ErrorModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content} | Corpus Coranicum` : `Corpus Coranicum`), 1)
      ]),
      desription: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content} | Corpus Coranicum` : `Corpus Coranicum`), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header),
      (_ctx.$route.matched.findIndex((r) => r.name === 'VerseNavigator') >= 0)
        ? (_openBlock(), _createBlock(_component_VerseNavBar, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_view, { class: "flex-grow" })
        ])
      ]),
      _createVNode(_component_Footer)
    ]),
    (_ctx.errors.length > 0)
      ? (_openBlock(), _createBlock(_component_ErrorModal, {
          key: 0,
          onClose: _ctx.clearErrors
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}