import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pb-3" }
const _hoisted_2 = { class: "py-2" }
const _hoisted_3 = { class: "py-2" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.$t(_ctx.titleString)
    }, null, 8, ["title"]),
    (_ctx.isLoaded(_ctx.events))
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.events.payload, (e) => {
          return (_openBlock(), _createBlock(_component_ArticleContainer, {
            key: e.title,
            class: "showLinks my-3"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_1, _toDisplayString(e.title), 1),
              _createElementVNode("p", _hoisted_2, _toDisplayString(`${_ctx.$t('global.location')}: ${e.location}`), 1),
              _createElementVNode("p", _hoisted_3, _toDisplayString(`${_ctx.$t('global.date')}: ${_ctx.printDate(e.start)}`), 1),
              _createElementVNode("p", {
                class: "py-2",
                innerHTML: e.description
              }, null, 8, _hoisted_4)
            ]),
            _: 2
          }, 1024))
        }), 128))
      : _createCommentVNode("", true)
  ], 64))
}