import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "z-50 absolute top-0 left-0 right-0 min-h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_VerseLinks = _resolveComponent("VerseLinks")!
  const _component_NavLinks = _resolveComponent("NavLinks")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CloseIcon, {
      class: "w-16 cursor-pointer float-right",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }),
    (_ctx.verseLinks)
      ? (_openBlock(), _createBlock(_component_VerseLinks, {
          key: 0,
          onLinkClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
          class: "mt-16"
        }))
      : (_openBlock(), _createBlock(_component_NavLinks, {
          key: 1,
          mobile: true,
          class: "mt-16",
          onLinkClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
        }))
  ]))
}