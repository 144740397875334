import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pb-2" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.$t('navigation.contributors')
    }, null, 8, ["title"]),
    (_ctx.isLoaded(_ctx.contributors))
      ? (_openBlock(), _createBlock(_component_ArticleContainer, {
          key: 0,
          class: "mb-6"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contributors.payload, (group) => {
              return (_openBlock(), _createElementBlock("div", {
                key: group.role,
                class: "py-3 showLinks"
              }, [
                _createElementVNode("h3", _hoisted_1, _toDisplayString(group.role), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.people, (p) => {
                  return (_openBlock(), _createElementBlock("p", {
                    key: p,
                    innerHTML: p,
                    class: "pl-2"
                  }, null, 8, _hoisted_2))
                }), 128))
              ]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}