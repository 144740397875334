
import { defineComponent, PropType } from 'vue';
import NavigationLink from '@/interfaces/NavigationLink';

export default defineComponent({
  props: {
    links: Object as PropType<NavigationLink[]>, mobile: Boolean,
  },
  emits: ['clicked'],
});
