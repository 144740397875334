
import { defineComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import SimpleCard from '@/components/global/SimpleCard.vue';
import ManuscriptSelect from '@/components/manuscripts/ManuscriptSelect.vue';
import ManuscriptPagesForVerse from '@/components/manuscripts/ManuscriptPagesForVerse.vue';
import VerseSelect from '@/components/verse_navigation/VerseSelect.vue';
import { useI18n } from 'vue-i18n';
import { getManuscriptVerseresults } from '@/api/manuscripts';
import { routeQueryToVerse } from '@/router/route_to_verse';
import { verseLinks } from '../verse_navigation/verse_links';

export default defineComponent({
  components: {
    SimpleCard,
    VerseSelect,
    ManuscriptSelect,
    ManuscriptPagesForVerse,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    getManuscriptVerseresults(routeQueryToVerse(route));

    const links = verseLinks(t);
    watch([() => route.query.sura, () => route.query.verse], () => {
      if (route.name === 'ManuscriptSingle' || route.name === 'ManuscriptPage') {
        getManuscriptVerseresults(routeQueryToVerse(route));
      }
    });
    return {
      route,
      links,
    };
  },
});
