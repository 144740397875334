import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-wrap sm:flex-nowrap justify-end items-center text-black" }
const _hoisted_2 = { class: "flex my-2" }
const _hoisted_3 = {
  class: "font-bold",
  for: "chapter"
}
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "flex ml-2 my-2" }
const _hoisted_7 = {
  class: "inline-block font-bold",
  for: "verse"
}
const _hoisted_8 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('navigation.sura')), 1),
      _createElementVNode("select", {
        class: "w-48 mx-2 bg-grey-light text-black",
        name: "chapter",
        id: "chapter",
        value: _ctx.selectedChapter,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setSuraAndResetVerse && _ctx.setSuraAndResetVerse(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chapters.payload, (chapter) => {
          return (_openBlock(), _createElementBlock("option", {
            key: chapter.key,
            value: chapter.key
          }, _toDisplayString(chapter.key + ' ' + ' ' + chapter.nameTranslit), 9, _hoisted_5))
        }), 128))
      ], 40, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('navigation.verse')), 1),
      _withDirectives(_createElementVNode("select", {
        class: "w-14 mx-2 bg-grey-light text-black",
        name: "verse",
        id: "verse",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedVerse) = $event)),
        onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.verses, (n) => {
          return (_openBlock(), _createElementBlock("option", {
            key: n,
            value: n
          }, _toDisplayString(n), 9, _hoisted_8))
        }), 128))
      ], 544), [
        [_vModelSelect, _ctx.selectedVerse]
      ])
    ])
  ]))
}