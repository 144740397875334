import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["innerHTML", "dir"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowIcon = _resolveComponent("ArrowIcon")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    title: _ctx.title,
    informationText: _ctx.informationText,
    class: "relative"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["p-3", [_ctx.open ? '' : 'h-40 overflow-y-auto', _ctx.isOverflowing ? 'pb-8' : '']]),
        ref: "container"
      }, [
        (_ctx.htmlContent)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              innerHTML: _ctx.htmlContent,
              dir: _ctx.direction,
              class: _normalizeClass(_ctx.direction == 'rtl' ? 'arabic' : '')
            }, null, 10, _hoisted_1))
          : _renderSlot(_ctx.$slots, "default", { key: 1 })
      ], 2),
      (_ctx.isOverflowing)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open)),
            class: "px-3 text-xl bg-green-800 text-center text-white bg-opacity-70 absolute left-0 right-0 bottom-3 align-bottom cursor-pointer"
          }, [
            _createTextVNode(_toDisplayString(_ctx.open ? _ctx.$t('global.close') : _ctx.$t('global.expand')) + " ", 1),
            (!_ctx.open)
              ? (_openBlock(), _createBlock(_component_ArrowIcon, {
                  key: 0,
                  type: "down",
                  width: 18,
                  class: "inline"
                }))
              : (_openBlock(), _createBlock(_component_ArrowIcon, {
                  key: 1,
                  type: "up",
                  width: 18,
                  class: "inline"
                }))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["title", "informationText"]))
}