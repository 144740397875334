<template>
  <div
    class="
      loader
      ease-linear
      rounded-full
      border-4 border-t-4 border-green-200
      -mt-8
      -ml-6
      h-12
      w-12
      mb-4
    "
  />
</template>
