
import { defineComponent, ref } from 'vue';
import MoreInfo from '@/components/global/MoreInfo.vue';

export default defineComponent({
  components: { MoreInfo },
  props: {
    title: String,
    infoText: String,
  },
  setup() {
    const showInfo = ref(false);
    return { showInfo };
  },
});
