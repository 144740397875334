
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const markings = [
      { id: 'unavailable' },
      { id: 'unclear' },
      { id: 'missing' },
      { id: 'added' },
      { id: 'variation' },
      { id: 'modified' },
      { id: 'erased' },
    ];
    return { markings };
  },
});
