
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    imageCaption: String,
    imageCaptionLink: String,
    largeCaption: Boolean,
  },
});
