
import { computed, defineComponent, ref } from 'vue';
import Card from '@/components/global/Card.vue';
import ArrowIcon from '@/components/icons/ArrowIcon.vue';

export default defineComponent({
  components: {
    Card,
    ArrowIcon,
  },
  props: {
    title: String,
    subtitle: {
      type: String,
      required: false,
    },
    informationText: {
      type: String,
      required: false,
    },
    htmlContent: {
      type: String,
      required: false,
    },
    direction: {
      type: String,
      required: false,
    },
  },
  setup() {
    const container = ref<HTMLElement | null>(null);
    const open = ref(false);

    const isOverflowing = computed(() => {
      if (container.value) {
        return container.value.offsetHeight < container.value.scrollHeight;
      }
      return false;
    });

    return {
      open,
      container,
      isOverflowing,
    };
  },
});
