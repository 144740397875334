
import { defineComponent, ref, Ref } from 'vue';
import HomeArea from '@/components/header/HomeArea.vue';
import NavBar from '@/components/header/Navbar.vue';
import LanguageMenu from '@/components/header/LanguageMenu.vue';
import MenuIcon from '@/components/icons/MenuIcon.vue';
import MobileNav from '@/components/header/MobileNav.vue';

export default defineComponent({
  components: {
    HomeArea,
    NavBar,
    LanguageMenu,
    MenuIcon,
    MobileNav,
  },
  setup() {
    const mobileMenu: Ref<boolean> = ref(false);

    return { mobileMenu };
  },
});
