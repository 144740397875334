
import { defineComponent } from 'vue';
import { errors } from '@/api/base';
import Modal from './Modal.vue';

export default defineComponent({
  components: { Modal },
  setup() {
    return { errors };
  },
});
