
import { defineComponent, PropType } from 'vue';
import { ManuscriptDetail } from '@/interfaces/ManuscriptDetail';
import SimpleCard from '@/components/global/SimpleCard.vue';
import GeneralInfo from '@/components/manuscripts/GeneralInfo.vue';
import PageSelect from './PageSelect.vue';
import PrimaryButton from '../global/PrimaryButton.vue';

export default defineComponent({
  components: {
    SimpleCard, GeneralInfo, PageSelect, PrimaryButton,
  },
  props: { manuscript: Object as PropType<ManuscriptDetail> },
  setup() {
    return {};
  },
});
