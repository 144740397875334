import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "text-center py-3"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 3,
  class: "list-disc py-3 pl-8"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 4 }
const _hoisted_7 = {
  key: 5,
  class: "pb-3 showLinks"
}
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageHolder = _resolveComponent("ImageHolder")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!

  return (_openBlock(), _createBlock(_component_ArticleContainer, { class: "infoitem" }, {
    default: _withCtx(() => [
      _createElementVNode("h2", {
        id: _ctx.item.id,
        class: "text-center py-3"
      }, _toDisplayString(_ctx.item.title), 9, _hoisted_1),
      (_ctx.item.subtitle)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.item.subtitle), 1))
        : _createCommentVNode("", true),
      (_ctx.item.image)
        ? (_openBlock(), _createBlock(_component_ImageHolder, {
            key: 1,
            class: "py-3 text-center",
            imageUrl: _ctx.publicPath + 'img/' + _ctx.item.image,
            imageCaption: _ctx.item.image_caption,
            loadingHeight: 100
          }, null, 8, ["imageUrl", "imageCaption"]))
        : _createCommentVNode("", true),
      (_ctx.item.html)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            innerHTML: _ctx.item.html,
            class: "htmlContent showLinks"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.item.bullets)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.bullets, (b) => {
              return (_openBlock(), _createElementBlock("li", { key: b }, _toDisplayString(b), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.paragraphs, (p, index) => {
        return (_openBlock(), _createElementBlock("p", {
          key: index,
          innerHTML: p
        }, null, 8, _hoisted_5))
      }), 128)),
      (_ctx.item.links)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_6, "Links"))
        : _createCommentVNode("", true),
      (_ctx.item.links)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.links, (l) => {
              return (_openBlock(), _createElementBlock("li", {
                key: l.url
              }, [
                _createElementVNode("a", {
                  href: 
              l.add_language
                ? `/${_ctx.$i18n.locale}${l.url}`
                : l.url
            
                }, _toDisplayString(l.text), 9, _hoisted_8)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}