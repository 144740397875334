
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    citation: String, precitation: String,
  },
  setup(props) {
    const mainCitation = computed(() => {
      if (props.citation) {
        const date = new Date(Date.now());
        const formatted = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
        return props.citation.replace('%DATE%', formatted);
      }
      return '';
    });
    return { mainCitation };
  },
});
