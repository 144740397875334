import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-96 text-center flex flex-col justify-center px-2 my-3 max-w-screen-md w-full mx-auto bg-white"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OpenSeaDragonViewer = _resolveComponent("OpenSeaDragonViewer")!

  return (typeof _ctx.images === 'string' || _ctx.images.length === 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (typeof _ctx.images === 'string')
          ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.$t('global.image_not_allowed')), 1))
          : (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.$t('global.no_image_available')), 1))
      ]))
    : (_openBlock(), _createBlock(_component_OpenSeaDragonViewer, {
        key: 1,
        image_links: _ctx.images.map((i) => i.external_url),
        iiifJsonUrls: _ctx.images.map((i) => i.iiif_url),
        image_sources: _ctx.images.map((i) => i.image_source)
      }, null, 8, ["image_links", "iiifJsonUrls", "image_sources"]))
}