
import { defineComponent } from 'vue';
import NavLinks from '@/components/header/Navbar.vue';
import VerseLinks from '@/components/verse_navigation/VerseLinks.vue';
import CloseIcon from '@/components/icons/CloseIcon.vue';

export default defineComponent({
  components: { NavLinks, VerseLinks, CloseIcon },
  props: { verseLinks: Boolean },
});
