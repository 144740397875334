import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "py-6",
  id: "manuscript_page"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "relative h-96"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageNav = _resolveComponent("PageNav")!
  const _component_MaybePageImages = _resolveComponent("MaybePageImages")!
  const _component_TransliterationKey = _resolveComponent("TransliterationKey")!
  const _component_Card = _resolveComponent("Card")!
  const _component_TwoItems = _resolveComponent("TwoItems")!
  const _component_ReferenceVerses = _resolveComponent("ReferenceVerses")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageNav),
    (_ctx.isLoaded(_ctx.pageDetail))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.pageDetail.payload.transliteration)
            ? (_openBlock(), _createBlock(_component_MaybePageImages, {
                key: 0,
                images: _ctx.pageDetail.payload.images
              }, null, 8, ["images"]))
            : (_openBlock(), _createBlock(_component_TwoItems, { key: 1 }, {
                first: _withCtx(() => [
                  _createVNode(_component_MaybePageImages, {
                    images: _ctx.pageDetail.payload.images
                  }, null, 8, ["images"])
                ]),
                second: _withCtx(() => [
                  (_ctx.pageDetail.payload.transliteration)
                    ? (_openBlock(), _createBlock(_component_Card, {
                        key: 0,
                        title: _ctx.$t('manuscripts.transliteration'),
                        informationText: _ctx.manuscriptWeb.payload?.transliteration_info
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("table", {
                            class: "transliteration table-auto arabic pt-3 w-full",
                            innerHTML: _ctx.pageDetail.payload.transliteration
                          }, null, 8, _hoisted_3),
                          _createVNode(_component_TransliterationKey, { class: "mx-2 pt-8 pb-2" })
                        ]),
                        _: 1
                      }, 8, ["title", "informationText"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })),
          _createVNode(_component_ReferenceVerses, {
            verses: _ctx.pageDetail.payload.verses,
            class: "pt-6"
          }, null, 8, ["verses"])
        ]))
      : (_ctx.isLoading(_ctx.pageDetail))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_LoadingSpinner, { class: "absolute top-1/2 left-1/2 z-10" })
          ]))
        : _createCommentVNode("", true)
  ]))
}