
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'ArrowIcon',
  props: {
    type: String,
    width: Number,
  },
})
export default class ArrowIcon extends Vue {
  type!: string;

  get path(): string {
    switch (this.type) {
      case 'back':
        return 'M328 112L184 256l144 144';
      case 'forward':
        return 'M184 112l144 144-144 144';
      case 'up':
        return 'M112 328l144-144 144 144';
      case 'down':
        return 'M112 184l144 144 144-144';
      default:
        return '';
    }
  }
}
