
import { defineComponent, PropType } from 'vue';
import { VerseTranslation } from '@/interfaces/VerseTranslaton';
import ScrollCard from '@/components/global/ScrollCard.vue';
import TwoItems from '@/components/global/TwoItems.vue';
import { manuscriptWeb } from '@/api/web';

export default defineComponent({
  components: {
    TwoItems,
    ScrollCard,
  },
  props: { verses: Object as PropType<Array<VerseTranslation> | string> },
  setup() {
    return { manuscriptWeb };
  },
});
