import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border" }
const _hoisted_2 = { class: "border" }
const _hoisted_3 = { class: "border" }
const _hoisted_4 = { class: "border" }
const _hoisted_5 = { class: "h-24 border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("table", _hoisted_1, [
      _createElementVNode("tr", _hoisted_2, [
        _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t('manuscripts.mark')), 1),
        _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t('manuscripts.meaning')), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markings, (mark) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: mark.id,
          class: "border"
        }, [
          _createElementVNode("td", {
            class: _normalizeClass([mark.id, "border h-24 text-center"])
          }, _toDisplayString(_ctx.$t(`manuscripts.markings.${mark.id}`)), 3),
          _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.$t(`manuscripts.markings.${mark.id}_explanation`)), 1)
        ]))
      }), 128))
    ])
  ]))
}