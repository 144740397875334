
import { defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import PrimaryButton from '@/components/global/PrimaryButton.vue';
import PlayButtonContent from '@/components/global/PlayButtonContent.vue';

export default defineComponent({
  components: {
    PrimaryButton,
    PlayButtonContent,
  },
  props: {
    disabled: Boolean,
    arrowType: String,
    text: String,
    textFirst: Boolean,
    internalLink: Object as PropType<RouteLocationRaw>,
  },
});
