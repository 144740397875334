
import { defineComponent } from 'vue';
import PrimaryButton from './PrimaryButton.vue';

export default defineComponent({
  components: { PrimaryButton },
  setup() {
    console.log('');
  },
});
