import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-center py-2 showLinks"
}
const _hoisted_3 = {
  key: 1,
  class: "text-center py-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("p", null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(`${_ctx.$t('manuscripts.pages_for_verse', {
          sura: _ctx.$route.query.sura,
          verse: _ctx.$route.query.verse,
        })}: `), 1),
    (_ctx.pagesForVerse.length > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pagesForVerse, (p) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              class: "px-2",
              key: p.folio + p.side,
              to: {
          name: 'ManuscriptPage',
          params: { page: p.folio + p.side },
          query: _ctx.$route.query,
          hash: '#manuscript_page',
        }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(p.folio + p.side), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]))
      : (_ctx.isLoaded(_ctx.manuscriptDetail))
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('manuscripts.none')), 1))
        : _createCommentVNode("", true)
  ]))
}