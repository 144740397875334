
import { isLoading, isLoaded, notFound, RemoteData } from '@/interfaces/RemoteData';
import { defineComponent, PropType, ref, Ref } from 'vue';
import LoadingSpinner from './LoadingSpinner.vue';

export default defineComponent({
  components: { LoadingSpinner },
  props: { data: Object as PropType<RemoteData<any>> },
  setup() {
    const showLoadingSpinner: Ref<boolean> = ref(false);
    setTimeout(() => {
      showLoadingSpinner.value = true;
    }, 1000);

    return {
      isLoaded,
      isLoading,
      notFound,
      showLoadingSpinner,
    };
  },
});
