
import { defineComponent, PropType } from 'vue';
import { ManuscriptDetail, manuscriptTitle } from '@/interfaces/ManuscriptDetail';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import ArticleSection from '@/components/global/ArticleSection.vue';

export default defineComponent({
  methods: { manuscriptTitle },
  components: {
    ArticleContainer,
    ArticleSection,
  },
  props: {
    manuscript: {
      type: Object as PropType<ManuscriptDetail>,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
