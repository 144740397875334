import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-green-200 text-center flex justify-center h-24" }
const _hoisted_2 = { class: "bg-green-200 flex justify-end md:justify-start w-full container mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileNav = _resolveComponent("MobileNav")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_LanguageMenu = _resolveComponent("LanguageMenu")!
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_HomeArea = _resolveComponent("HomeArea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mobileMenu)
      ? (_openBlock(), _createBlock(_component_MobileNav, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mobileMenu = false)),
          class: "bg-green-200"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NavBar, { class: "md:flex hidden flex-grow" }),
      _createVNode(_component_LanguageMenu, { class: "md:block hidden self-end my-4 mx-4" }),
      _createVNode(_component_MenuIcon, {
        class: "md:hidden cursor-pointer h-24",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mobileMenu = true))
      }),
      _createVNode(_component_HomeArea, { class: "md:flex-grow-0 flex-grow" })
    ])
  ]))
}