import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "showLinks" }
const _hoisted_4 = {
  key: 1,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerseSelect = _resolveComponent("VerseSelect")!
  const _component_ManuscriptSelect = _resolveComponent("ManuscriptSelect")!
  const _component_ManuscriptPagesForVerse = _resolveComponent("ManuscriptPagesForVerse")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SimpleCard = _resolveComponent("SimpleCard")!

  return (_openBlock(), _createBlock(_component_SimpleCard, { class: "w-full block mx-auto mt-4" }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('navigation.verse_menu')), 1),
      _createVNode(_component_VerseSelect),
      (_ctx.route.query.sura && _ctx.route.query.verse)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ManuscriptSelect, {
              sura: +_ctx.route.query.sura,
              verse: +_ctx.route.query.verse,
              justNavigation: false,
              selected: +_ctx.route.params.manuscript
            }, null, 8, ["sura", "verse", "selected"]),
            _createVNode(_component_ManuscriptPagesForVerse, { class: "pt-4 pb-4" }),
            _createElementVNode("div", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.verse_links')) + ": ", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  class: "inline-block px-1",
                  key: link.to.name,
                  to: {
            name: link.to.name,
            params: {
              ...link.to.params,
              sura: _ctx.$route.query.sura,
              verse: _ctx.$route.query.verse,
            },
          }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(link.label()), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(`(${_ctx.$t('global.no_verse_selected')})`), 1))
    ]),
    _: 1
  }))
}