import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayButtonContent = _resolveComponent("PlayButtonContent")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!

  return (_openBlock(), _createBlock(_component_PrimaryButton, null, {
    default: _withCtx(() => [
      (_ctx.internalLink)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: _ctx.internalLink
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PlayButtonContent, {
                arrowType: _ctx.arrowType,
                text: _ctx.text,
                textFirst: _ctx.textFirst
              }, null, 8, ["arrowType", "text", "textFirst"])
            ]),
            _: 1
          }, 8, ["to"]))
        : (_openBlock(), _createBlock(_component_PlayButtonContent, {
            key: 1,
            arrowType: _ctx.arrowType,
            text: _ctx.text,
            textFirst: _ctx.textFirst,
            class: _normalizeClass(_ctx.disabled ? 'text-white cursor-not-allowed' : '')
          }, null, 8, ["arrowType", "text", "textFirst", "class"]))
    ]),
    _: 1
  }))
}