import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}
const _hoisted_3 = { class: "pl-1" }
const _hoisted_4 = { class: "arabic text-2xl" }
const _hoisted_5 = { class: "pr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollCard = _resolveComponent("ScrollCard")!
  const _component_TwoItems = _resolveComponent("TwoItems")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('manuscripts.reference_text')), 1),
    (typeof _ctx.verses === 'string')
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, " (" + _toDisplayString(_ctx.$t('global.verse_range_too_large')) + ") ", 1))
      : (_openBlock(), _createBlock(_component_TwoItems, { key: 1 }, {
          first: _withCtx(() => [
            _createVNode(_component_ScrollCard, {
              title: _ctx.$t('print_edition.arabic_header'),
              informationText: _ctx.manuscriptWeb.payload?.arabic_text_info
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.verses, (v) => {
                  return (_openBlock(), _createElementBlock("p", {
                    key: v.arabic,
                    class: "arabic",
                    dir: "rtl"
                  }, [
                    _createElementVNode("sup", _hoisted_3, _toDisplayString(`${v.sura}:${v.verse}`), 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(v.arabic), 1)
                  ]))
                }), 128))
              ]),
              _: 1
            }, 8, ["title", "informationText"])
          ]),
          second: _withCtx(() => [
            _createVNode(_component_ScrollCard, {
              title: _ctx.$t('print_edition.translation_header'),
              informationText: _ctx.manuscriptWeb.payload?.translation_info
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.verses, (v) => {
                  return (_openBlock(), _createElementBlock("p", {
                    key: v.translation
                  }, [
                    _createElementVNode("sup", _hoisted_5, _toDisplayString(`${v.sura}:${v.verse}`), 1),
                    _createElementVNode("span", null, _toDisplayString(v.translation), 1)
                  ]))
                }), 128))
              ]),
              _: 1
            }, 8, ["title", "informationText"])
          ]),
          _: 1
        }))
  ]))
}